import Vue from "vue";
import App from "./App.vue";
import router from "./router"; //加载router文件夹下的index文件
import store from "./store";
import api from "./utils/api";
import common from "./utils/common";
import "./utils/directives";
import "_as/styles/common.css";
import "_as/font/iconfont.css";
import "_as/font/iconfont.js";

//导入element-ui框架
import ElementUI from "element-ui";
/* import 'element-ui/lib/theme-chalk/reset.css' */
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI); //使用elementUI插件

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

//引入vue-echart
import Echarts from "vue-echarts";
Vue.component("v-charts", Echarts);

import * as echarts from 'echarts';

//使用Less
import less from "less";
Vue.use(less);

//使用百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  // ak: "0rfOISi7boo2aax1y7NXtTqCkhHZUGUW" //小肖的ID
  ak: 'GnrQoSv7uBTcuEgnXGrcg9XBZIjcaadh'
});

// import VideoPlayer from 'vue-video-player'
// // require('video.js/dist/video-js.css')
// // require('vue-video-player/src/custom-theme.css')
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// Vue.use(VideoPlayer);
// import 'videojs-flash'

//引入全局接口api
Vue.prototype.$api = api;
Vue.prototype.$echarts = echarts;
Vue.prototype.$common = common;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

common.rewirteLog();

// new TerserPlugin({
//   cache: true,
//   sourceMap: false,
//   // 多进程
//   parallel: true,
//   terserOptions: {
//       ecma: undefined,
//       warnings: false,
//       parse: {},
//       compress: {
//         drop_console: true,
//         drop_debugger: false,
//         pure_funcs: ['console.log'], // 移除console
//       },
//     },
// });


