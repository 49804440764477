//导入axios插件
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {Message, MessageBox} from 'element-ui'
import router from "../router";
import common from './common.js'

Vue.use(VueAxios, axios);
let is_env_dev = process.env.NODE_ENV === 'development';
console.log({'is_env_dev': is_env_dev, 'NODE_ENV': process.env.NODE_ENV});
const baseUrl = is_env_dev ? '' : 'https://www.mifulink.com';
const url2 = baseUrl + '/runze_open_api/';

// console.log(baseUrl);
// console.log(url2);

// 添加请求拦截器
axios.interceptors.request.use((config) => {
	//console.log(config);
	//统一添加token参数，如果带有json_data则转换成字符串
	const user = JSON.parse(localStorage.getItem('user')),
		appid = "74237afdb1b420bcc59f2250da9a43c7";

	if(user) {
		const token = user.token;
		//console.log(Object.keys(config.data));
		if(config.data.json_data) {
			config.data.json_data.appid = appid;
			config.data.json_data.token = token;
			config.data.json_data = JSON.stringify(config.data.json_data);
		} else {
			config.data.token = token;
			config.data.appid = appid;
		}
	} else {
		config.data.appid = appid;
	}
	
	//console.log(config.data);
	return config;
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	//console.log(response)
	/*
		code: 1 获取数据失败, 
						1、登录超时，跳转至登录页
						2、直接输出失败消息
		code: 0 获取数据成功，返回数据
	*/
	const resData = response.data;
	//console.log(resData)
	if(resData.code == 1) {
		if(resData.msg.indexOf('登录超时') != -1) {
			MessageBox.confirm(resData.msg,'登录超时',{
				showCancelButton: false,
				confirmButtonText: '返回登录界面'
			}).then(() =>{
				router.replace({path: '/login'});
				common.clearCookie('sessionId');	//清除token
			})
		}else {
			Message({
				type: 'warning', //type: 'warning' success info warning error
				message: resData.msg
			});
		}
	}else {
		if(resData.data) {
			return resData.data;
		}else {
			return resData;
		}
	}
}, (error) => {
	//console.log(error)
	if(error.message.includes('timeout')) {
		Message({
			type: 'error',
			message: "请求超时"
		});
	}else {
		Message({
			type: 'error',
			message: error
		});
	}
});

export default {
	request_02: function(params) {
		return Vue.axios({
			baseURL: url2, //将自动加载url前面,除非
			url: params.url, //用于请求服务器的URL
			method: 'post', //请求的方法,默认为get
			headers: {
				"Content-Type": "application/json"
			},
			params: {       //与请求一起发送的URL参数
			}, 
			data: params.data,//作为请求主体被发送的数据  transformResponse: [function(res) {}]
			timeout: 30000 //指定请求超时的毫秒数,0表示没有超时时间
		});
	},
}
