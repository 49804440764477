export default {
	//将浏览器的cookie对象化输出
	getCookieByName: function(name){
		let cookieStr = document.cookie;
		function trim(str){ //删除左右两端的空格
			return str.replace(/(^\s*)|(\s*$)/g, "");
		}
		let result = {};
		if(cookieStr !== ""){
			//字符串转数组，以"="为分割点
			let strs = cookieStr.split(";");
			for(let i=0,len=strs.length; i<len; i++){
				let str = strs[i].split("=");									
				result[trim(str[0])] = str[1];
			}
			return result[name];
		}
	},
	//全屏
	fullScreen: function(fullscreen,element=document.documentElement){
		if (fullscreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		} else {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
				element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
				// IE11
				element.msRequestFullscreen();
			}
		}
	},
	//清除cookie
	clearCookie: function(name) {
		//清除cookie
		let d = new Date();
		d.setTime(d.getTime() - (24*60*60*1000));
		let expires = "expires="+d.toUTCString();
		document.cookie = name + "=1;" + expires;
	},
	//特定格式的时间
	formatDate: function(date,format="yyyy-MM-dd hh:mm:ss") {
		if(typeof date !== "object") {
			var date = new Date(date);
		}
		let time = {
			'y+': date.getYear(),
			'M+': date.getMonth() + 1,
			'd+': date.getDate(),
			'h+': date.getHours(),
			'm+': date.getMinutes(),
			's+': date.getSeconds(),
			'q+': Math.floor((date.getMonth() + 3) / 3),
			'S+': date.getMilliseconds()
		}
		if(/(y+)/i.test(format)) {
			format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
		}
		
		for(let k in time) {
			if(new RegExp('(' + k + ')').test(format)) {
				format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? time[k] : ('00' + time[k]).substr(('' + time[k]).length));
			}
		}
		return format;
	},
	//字符串转GMT格式的时间
	strToGMT:	function (str) {
		let date = new Date(str);
		date.setTime(date.getTime() + 8*60*60*1000);
		return date.toGMTString();
	},
	/* 重写console.log方法，vue 在生产环境下，屏蔽console.log在控制台输出*/
	rewirteLog() {
		console.log = (function (log) {
		  return process.env.NODE_ENV == 'production' ? function () { } : log
		}(console.log))
	}	  
}