import Vue from 'vue'
import VueRouter from 'vue-router'
import staticRoute from './staticRoute'
import common from '../utils/common.js'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
  routes: staticRoute
})

//前置守卫
router.beforeEach((to,from,next) => {
	//进入的不是登录界面都要进行验证token是否存在或过期
	if(to.name !== "login") {
		//查看是否已登录或过期
		const sessionId = common.getCookieByName("sessionId");
		if(sessionId) {
			next();
		}else {
			next({name:'login'})
		}
	}else {
		next()
	}
})

export default router  //返回一个router实例
