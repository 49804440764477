import http from './http.js'

let apiUrl = {
	login: 'get_token',//登录
	getStaticAbout: 'get_static_about',
	
	//配置项
	getDeviceCategory: "get_device_category_list", //获取分类子系统列表
	getAlarmCause: 'get_alarm_reason_item', //获取报警原因列表
	
	getAreaMapList: 'runze_area_map_list', //根据等级获取统计分析数据
	getArchiveReport: 'get_archives_report', //获取验收报告
	
	//项目相关
	getCompanyList: 'get_projects',//获取项目列表
	getAllDeviceDetail: "get_device_info_project",//获取项目下所有的设备详细信息
	getBuildingList: "get_building_list",//获取账号下的所有建筑
	getBuildingTypeList: "get_building_type_list",//获取所有建筑类型
	
	//设备相关
	getDevList: "get_project_devices",//获取单项目设备列表
	getDeviceDetail: "get_device_info",//获取设备的详细信息
	getDeviceCurve: "get_device_param_curve",//获取设备的历史数据
	getAllDeviceList: "runze_get_device_list",//获取账号下的所有设备	
	
	//报警相关
	getActiveAlarm: "get_alarm_active",//获取实时报警信息
	getAlarmList: "get_alarm_list",//获取报警列表信息，alarm_type: 1——实时  0——历史
	getAlarmSmsPush: 'get_information_sms_push' ,//获取报警短信推送列表
	handleAlarm: 'deal_alarm_active', //处理实时报警
	
	getUserList: 'get_user_list',
	orgTreeList: 'get_org_list_tree',
	addOrgNode: 'add_org_node',
	getAreaCodeList: 'get_area_code_list',
	edtOrgCompanyInfo: 'edt_org_company_info'
}

function request(data,url) {
	return http.request_02({data: data || {}, url});
}

export default {
	login(data) {
		return request(data,apiUrl.login);
	},
	getStaticAbout(data) {
		return request(data,apiUrl.getStaticAbout);
	},
	getAreaMapList(data) {
		return request(data,apiUrl.getAreaMapList);
	},
	getArchiveReport(data) {
		return request(data,apiUrl.getArchiveReport);
	},
	getCompanyList(data){
		return request(data,apiUrl.getCompanyList);
	},
	getDevList(data) {
		return request(data,apiUrl.getDevList);
	},
	getDeviceCurve(data) {
		return request(data,apiUrl.getDeviceCurve);
	},
	getDeviceDetail(data) {
		return request(data,apiUrl.getDeviceDetail);
	},
	getAllDeviceDetail(data) {
		return request(data,apiUrl.getAllDeviceDetail);
	},
	getAnalysisData(url) {
		return request({},url);
	},
	getActiveAlarm(data) {
		return request(data,apiUrl.getActiveAlarm);
	},
	getAlarmList(data) {
		return request(data, apiUrl.getAlarmList);
	},
	getAllDeviceList(data) {
		return request(data, apiUrl.getAllDeviceList);
	},
	getAlarmSmsPush(data) {
		return request(data, apiUrl.getAlarmSmsPush);
	},
	getAlarmCause(data) {
		return request(data, apiUrl.getAlarmCause);
	},
	handleAlarm(data) {
		return request(data, apiUrl.handleAlarm);
	},
	getDeviceCategory(data) {
		return request(data, apiUrl.getDeviceCategory);
	},
	getUserList(data) {
		return request(data, apiUrl.getUserList);
	},
	getOrgTreeList(data) {
		return request(data, apiUrl.orgTreeList);
	},
	getBuildingList(data) {
		return request(data, apiUrl.getBuildingList);
	},
	getBuildingTypeList(data) {
		return request(data, apiUrl.getBuildingTypeList);
	},
	addOrgNode(data) {
		return request(data, apiUrl.addOrgNode);
	},
	edtOrgCompanyInfo(data) {
		return request(data, apiUrl.edtOrgCompanyInfo);
	},
	getAreaCodeList(data) {
		return request(data, apiUrl.getAreaCodeList);
	}
}
