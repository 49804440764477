<template>
	<div id="app">
		<!-- 路由出口，匹配到的组件渲染在这 -->
		<router-view/>
	</div>
</template>
<script>
	export default {
		data() {
			return {};
		},
		mounted() {
			const innerWidth = window.innerWidth;
			let fontSize = innerWidth * 16 / 1920;
			document.documentElement.style.fontSize = fontSize + 'px';
		}
	};
</script>
<style>
	html,
	body,
	#app {
		padding: 0;
		margin: 0;
		height: 100%;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
</style>