import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//专注于状态(数据)变化
const mutations = {
	SETUSER(state,user) {
		console.log(state);
		state.user = user;
	}
}

//处理业务逻辑
const actions = {
	
}

export default new Vuex.Store({
  state: {
	  user: null,
  },
  mutations,
  actions
});
