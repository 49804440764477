export default [
	{
		path: "/admin",
		redirect: "admin/companyList",
		component: () => import("_v/layout"),
		children: [
			{
				path: "singleProject",
				name: "singleProject",
				components: {singleProject: () => import("_v/home/singleProject")},
				meta: {title: "单项目"}
			},
			{
				path: "companyList",
				name: "companyList",
				components: {companyList: () => import("_v/companyList")},
				meta: {title: "项目列表"}
			},
			{
				path: "deviceList",
				name: "deviceList",
				components: {deviceList: () => import("_v/deviceList")},
				meta: {title: "设备列表"}
			},
			{
				path: "buildingList",
				name: "buildingList",
				components: {buildingList: () => import("_v/buildingList")},
				meta: {title: "建筑列表"}
			},
			{
				path: "realAlarm",
				name: "realAlarm",
				components: {realAlarm: () => import("_v/realAlarm/realAlarm")},
				meta: {title: "实时报警"}
			},
			{
				path: "historicalAlarm",
				name: "historicalAlarm",
				components: {historicalAlarm: () => import("_v/HistoricalAlarm/HistoricalAlarm")},
				meta: {title: "历史报警"}
			},
			{
				path: "alarmSmsPush",
				name: "alarmSmsPush",
				components: {alarmSmsPush: () => import("_v/alarmSmsPush")},
				meta: {title: "报警推送"}
			},
			{
				path: "checkReport",
				name: "checkReport",
				components: {checkReport: () => import("_v/checkReport")},
				meta: {title: '验收报告'}
			},
			{
				path: "emergencyReport",
				name: "emergencyReport",
				components: {emergencyReport: () => import("_v/emergencyReport")},
				meta: {title: '应急报告'}
			},
			{
				path: "userList",
				name: "userList",
				components: {userList: () => import("_v/userList")},
				meta: {title: "用户列表"}
			},
			{
				path: "permission",
				name: "permission",
				components: {permission: () => import("_v/permission")},
				meta: {title: "事件联动"}
			},
			{
				path: "organization",
				name: "organization",
				components: {organization: () => import("_v/organization")},
				meta: {title: "机构管理"}
			}
		]
	},
	{
		path: "/login",
		name: "login",
		component: () => import("_v/login")
	},
	{
		path: '/',
		redirect: "/home"
	},
	{
		path: '/home',
		name: "home",
		component: () => import("_v/home"),
	},
	{
		path: '/v_1',
		name: "v_1",
		component: () => import("_v/videoRT"),
	}
]
